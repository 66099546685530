// extracted by mini-css-extract-plugin
export var actualLink = "blog-post-styles-module--actualLink--kuwbF";
export var fauxLink = "blog-post-styles-module--fauxLink--dbpqV";
export var textWidth = "blog-post-styles-module--textWidth--3X780";
export var pageHeader = "blog-post-styles-module--pageHeader--1FdZA";
export var pageTitle = "blog-post-styles-module--pageTitle--2UVwC";
export var pageSubTitle = "blog-post-styles-module--pageSubTitle--8mNBl";
export var unset = "blog-post-styles-module--unset--1GjxC";
export var resetList = "blog-post-styles-module--resetList--1f7Fz";
export var tac = "blog-post-styles-module--tac--3WXsG";
export var tal = "blog-post-styles-module--tal--2WcfS";
export var tar = "blog-post-styles-module--tar--1vOmP";
export var noBoxShadow = "blog-post-styles-module--no-box-shadow--37Z3b";
export var ptn = "blog-post-styles-module--ptn--Rlwr4";
export var pvn = "blog-post-styles-module--pvn--2q4t2";
export var pan = "blog-post-styles-module--pan--37rCZ";
export var ptxs = "blog-post-styles-module--ptxs--2oZiY";
export var pvxs = "blog-post-styles-module--pvxs--1J039";
export var paxs = "blog-post-styles-module--paxs--3Qnzn";
export var pts = "blog-post-styles-module--pts--h7fe2";
export var pvs = "blog-post-styles-module--pvs--qFBPU";
export var pas = "blog-post-styles-module--pas--imnm5";
export var ptm = "blog-post-styles-module--ptm--1B6jy";
export var pvm = "blog-post-styles-module--pvm--rfMFX";
export var pam = "blog-post-styles-module--pam--7UV5S";
export var ptl = "blog-post-styles-module--ptl--2OI7A";
export var pvl = "blog-post-styles-module--pvl--3sc3A";
export var pal = "blog-post-styles-module--pal--2ng7P";
export var ptxl = "blog-post-styles-module--ptxl--zTB4a";
export var pvxl = "blog-post-styles-module--pvxl--AM3Np";
export var paxl = "blog-post-styles-module--paxl--1OBKN";
export var prn = "blog-post-styles-module--prn--2HFVv";
export var phn = "blog-post-styles-module--phn--1R1Ca";
export var prxs = "blog-post-styles-module--prxs--3jfij";
export var phxs = "blog-post-styles-module--phxs--Wm3H_";
export var prs = "blog-post-styles-module--prs--1P7ek";
export var phs = "blog-post-styles-module--phs--XgGfI";
export var prm = "blog-post-styles-module--prm--2eZ0_";
export var phm = "blog-post-styles-module--phm--lmozp";
export var prl = "blog-post-styles-module--prl--1KlXj";
export var phl = "blog-post-styles-module--phl--Sgc2m";
export var prxl = "blog-post-styles-module--prxl---xmzK";
export var phxl = "blog-post-styles-module--phxl--2pepM";
export var pbn = "blog-post-styles-module--pbn--3Omgr";
export var pbxs = "blog-post-styles-module--pbxs--3z__-";
export var pbs = "blog-post-styles-module--pbs--1oJIc";
export var pbm = "blog-post-styles-module--pbm--3paWu";
export var pbl = "blog-post-styles-module--pbl--koRnf";
export var pbxl = "blog-post-styles-module--pbxl--3raGi";
export var pln = "blog-post-styles-module--pln--2in76";
export var plxs = "blog-post-styles-module--plxs--3peF9";
export var pls = "blog-post-styles-module--pls--3LNo9";
export var plm = "blog-post-styles-module--plm--Ea81I";
export var pll = "blog-post-styles-module--pll--3tHsD";
export var plxl = "blog-post-styles-module--plxl--28Xxw";
export var mtn = "blog-post-styles-module--mtn--2jA3G";
export var mvn = "blog-post-styles-module--mvn--2smnK";
export var man = "blog-post-styles-module--man--37OqF";
export var mtxs = "blog-post-styles-module--mtxs--3b2no";
export var mvxs = "blog-post-styles-module--mvxs--3e2xU";
export var maxs = "blog-post-styles-module--maxs--MT8tU";
export var mts = "blog-post-styles-module--mts--MdTwd";
export var mvs = "blog-post-styles-module--mvs--31Ffa";
export var mas = "blog-post-styles-module--mas--2Cuca";
export var mtm = "blog-post-styles-module--mtm--2mYUR";
export var mvm = "blog-post-styles-module--mvm--1O6Ta";
export var mam = "blog-post-styles-module--mam--pLKRm";
export var mtl = "blog-post-styles-module--mtl--2BkJC";
export var mvl = "blog-post-styles-module--mvl--2b4aQ";
export var mal = "blog-post-styles-module--mal--2-hEz";
export var mtxl = "blog-post-styles-module--mtxl--16vL8";
export var mvxl = "blog-post-styles-module--mvxl--2V5ym";
export var maxl = "blog-post-styles-module--maxl--Lpdxr";
export var mrn = "blog-post-styles-module--mrn--1wAHs";
export var mhn = "blog-post-styles-module--mhn--hxj7r";
export var mrxs = "blog-post-styles-module--mrxs--3hmML";
export var mhxs = "blog-post-styles-module--mhxs--2dluV";
export var mrs = "blog-post-styles-module--mrs--1Wzd9";
export var mhs = "blog-post-styles-module--mhs--1rTRg";
export var mrm = "blog-post-styles-module--mrm--3mNlq";
export var mhm = "blog-post-styles-module--mhm--3dN4t";
export var mrl = "blog-post-styles-module--mrl--3T3sf";
export var mhl = "blog-post-styles-module--mhl--33Hkk";
export var mrxl = "blog-post-styles-module--mrxl--Uqx7P";
export var mhxl = "blog-post-styles-module--mhxl--20xCj";
export var mbn = "blog-post-styles-module--mbn--2K9fv";
export var mbxs = "blog-post-styles-module--mbxs--31mpe";
export var mbs = "blog-post-styles-module--mbs--3bvzw";
export var mbm = "blog-post-styles-module--mbm--hJonj";
export var mbl = "blog-post-styles-module--mbl--1txmJ";
export var mbxl = "blog-post-styles-module--mbxl--1O7z8";
export var mln = "blog-post-styles-module--mln--tbLHZ";
export var mlxs = "blog-post-styles-module--mlxs--n-wau";
export var mls = "blog-post-styles-module--mls--XhdvO";
export var mlm = "blog-post-styles-module--mlm--2XAQW";
export var mll = "blog-post-styles-module--mll--3MUOW";
export var mlxl = "blog-post-styles-module--mlxl--2DXu9";
export var postInfo = "blog-post-styles-module--postInfo--pIuFt";
export var date = "blog-post-styles-module--date--2uIwG";
export var editLink = "blog-post-styles-module--editLink--3sAs5";
export var articleNav = "blog-post-styles-module--articleNav--3DeH9";
export var prevNextLabel = "blog-post-styles-module--prevNextLabel--dnJiG";